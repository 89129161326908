<template>
    <div style="width:100%">
         <app-image></app-image>
        <div class="localDiv">
            <div class="localContent">
                当前位置：网站首页 / <span>解决方案</span>
            </div>
        </div>
        <div class="solutionMenuDiv" id="showLocation">
            <p>解决方案</p>
            <div class="solutionMenuContent">
                <el-menu
                router
                :default-active="$route.path"
                mode="horizontal"
                class="el-menu-demo "
                text-color="#404756"
                active-text-color="#3C6AFB"
                ref="menu"
                >
                    <el-menu-item :index="item.value" v-for="(item,i) in newList" :key="i">{{item.name}}</el-menu-item>
                    <!-- <el-menu-item index="/solutionView/hulian" >互联网BI</el-menu-item>
                    <el-menu-item index="/solutionView/dizhi" >地质大数据</el-menu-item>
                    <el-menu-item index="/solutionByCondition/brand" >桥梁沉降智能监测</el-menu-item>
                    <el-menu-item index="/solutionByCondition/category" >智慧楼宇数字孪生</el-menu-item>
                    <el-menu-item index="/solutionByCondition/category" >智慧医院视频AI</el-menu-item>
                    <el-menu-item index="/solutionByCondition/category" >智慧工地人员定位</el-menu-item>
                    <el-menu-item index="/solutionView/wuzi" >智慧工地物资定位</el-menu-item> -->
                </el-menu>
                
                <div class="div1" v-if="menuList.length>5">
                    <i class="el-icon-arrow-left" @click="leftClick"></i>
                    <i class="el-icon-arrow-right" @click="rightClick"></i>
                </div>
            </div>
   
        </div>
        <div class="container">
            <div class="container1">
                <router-view/>
            </div>
        </div>
    </div>
</template>
<script>
import AppImage from '../../components/common/image.vue'
export default {
    name:"solution",
    components:{AppImage},
    data(){
        return{
            defaultPage:"/solutionView/jrrpa",
            menuList:[{name:"供应商风控大数据",value:"/solutionView/gysfkdsj"},
            {name:"金融风控大数据",value:"/solutionView/jrfkdsj"},
            {name:"基础设施监测",value:"/solutionView/jcssjc"},
            {name:"视频结构分析",value:"/solutionView/spjgfx"},
            {name:"财务RPA",value:"/solutionView/cwrpa"},
            {name:"制造业RPA",value:"/solutionView/zzyrpa"},
            {name:"BIM数据可视化和数字孪生",value:"/solutionView/bimsj"},
            ],
            newList:[],
            startNum:0,
            endNum:4
        }
    },
    methods:{
        init(){
            
            // let list=this.menuList
            // if(list.length>5){
            //     this.newList=list.slice(0,5);
            //     this.startNum=0
            //     this.endNum=5
            // }
            let name=this.$route.path
            let i = this.$route.path;
            setTimeout(() => { //路由跳转
                this.$refs.menu.activeIndex = i
            }, 100)
            // this.defaultPage=name
            // let items=this.newList.filter(item=>item.value==name)
            for(let i=0;i<this.menuList.length;i++){
                let item=this.menuList[i]
                if(item.value==name){
                    if(i<5){
                        // this.newList= this.menuList.slice(0,5); 
                        this.newList=Object.assign({}, this.menuList.slice(0,5));
                        this.startNum=0
                        this.endNum=5
                    }else{
                        this.startNum=i-4
                        this.endNum=i+1
                        // this.newList=this.menuList.slice(i-4,i+1); 
                        this.newList=Object.assign({}, this.menuList.slice(i-4,i+1));      
                    }
                     continue;
                }
            }
            
            this.$nextTick(function(){
                document.querySelector('#showLocation').scrollIntoView(true);
            })
            
            
        },

        leftClick(){
            if(this.startNum>0){
                let startNum=this.startNum-1
                let endNum=this.endNum-1
                this.newList=this.menuList.slice(startNum,endNum)
                this.startNum=startNum
                this.endNum=endNum
            }
        },
        rightClick(){
            let len=this.menuList.length;
            if(this.endNum+1<=len){
                let startNum=this.startNum+1
                let endNum=this.endNum+1
                this.newList=this.menuList.slice(startNum,endNum)
                this.startNum=startNum
                this.endNum=endNum
            }

        }
    },
    watch: {
        $route() {
            this.init()  //监听路由变化
        }
    },
    created(){
        this.init()
    },
    mounted(){
       
    }
}
</script>
<style>
.localDiv{
    background-color:#F8FAFD;
}
.localContent{
    text-align:right;
    padding:20px 0;
    font-size:15px;
    line-height:15px;
    background-color:#F8FAFD;
    width:70%;
    margin:0 auto;
    
}
.localContent span{
    color:#0665f3
}

.solutionMenuDiv {
    font-size:30px;
    padding-top:60px;
    text-align:center;
    /* border-bottom:1px solid #DBE3EF; */
    box-shadow: 1px 1px 5px #dbe3ef;
    
}

.solutionMenuContent{
    width:70%;
    margin:0 auto;
    padding-top:30px;
    height:50px;
    overflow-y:hidden;
    position:relative;
}
.solutionMenuContent .div1{
    position: absolute;
    top: 40px;
    right: 0px;
    color: gray;
    font-size: 16px;
    
}
.solutionMenuContent .div1 i{
   cursor:pointer
}
.container{
    
    background-image:url(../../static/about_bg1.png);
    background-repeat:no-repeat;
    background-position: bottom;
}
.container .container1{
    width:70%;
    margin:0 auto;
}
</style>
