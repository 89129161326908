
<template>
<div >
   <el-carousel  :height="windowH+'px'"  style="position:relative">
            <el-carousel-item v-for="(item,i) in imgList" :key="i" >
            <img :src="item.imgUrl"  alt="加载失败" :style="{'height':windowH+'px'}" :data-title="item.title" :class="{'link':item.link}"  @click="imageClick(item)"/>
            <!-- <div style="position:absolute;top:50px;left:300px;color:#fff">
                <div style="font-size:42px;font-weight:bold">{{item.title}}</div>
                <div style="font-size:24px;margin-top:20px" >{{item.content1}}</div>
                <div style="font-size:18px;text-indent:10em;margin-top:20px">{{item.content2}}</div>
            </div> -->
            </el-carousel-item>
        </el-carousel>
</div>
</template>

<script>
export default {
    name:"image",
    data(){
        return{
            imgList:[
                
                {
                    imgUrl:require("../../static/home/banner-1.png"),
                    title:"Cowise",
                    content1:"在线EXCEL协同",
                    content2:"数据汇集和数据计算工具",
                    link:'http://cowise.couponcad.com/cowiseIndex'
                },
                {
                    imgUrl:require("../../static/home/banner-2.jpg"),
                    title:"Minitab",
                    content1:"质量管理和流程改善工具",
                    content2:"",
                    link:'http://minitab.couponcad.com'
                },
                {
                   imgUrl: require("../../static/home/banner-3.png"),
                   title:"Nginxwise",
                   content1:"快速构建统一、整合的流量入口",
                   content2:"简化管理负载均衡，API网关",
                   link:'http://nginxwise.couponcad.com'
                },
                {
                    imgUrl:require("../../static/home/banner-4.png"),
                    title:"IoTWise",
                    content1:"物联网低代码PaaS",
                    content2:"建平台+用平台双轮驱动",
                    link:'http://haptic-devices.couponcad.com'
                },
                {
                    imgUrl:require("../../static/home/banner-5.png"),
                    title:"3dconnexion",
                    content1:"物联网低代码PaaS",
                    content2:"建平台+用平台双轮驱动",
                    link:'http://3dconnexion.couponcad.com/'
                },
                {
                    imgUrl:require("../../static/home/banner-6.png"),
                    title:"EHS",
                    content1:"EHS环境健康安全方案",
                    content2:"EHS环境健康安全方案",
                    link:'http://ehs.couponcad.com/'
                },
            ],
            windowH:0
        }
    },
    methods:{
        imageClick(item){
            if(item.link){
                window.open(item.link)
            }
        }
    },
    created(){
        let windowWidth = document.documentElement.clientWidth; //获取屏幕宽度
        windowWidth = windowWidth>=1300?windowWidth:1300
        this.windowH = windowWidth * (660/1920)
    }

}
</script>
<style scoped>
.textAlginLeft{
    text-align: left;
    
}
.navDiv{
    height: 60px;
    min-width:680px
}
.iconDot{
    display:none;
    width:4px;
    height:4px;
    background-color:#662299;
}
.link {
    cursor:pointer
}
</style>